<template>
    <div class="container">


        <h2 class="head2 mb-5">{{Lang.get('link9')}}</h2>
        <div class="text-red bold Truculenta text-37" style="max-width:682px;margin:auto;" v-html="Lang.get('text9')"></div>
        <div class="mt-5 mb-5">

            <img src="../assets/yatirimlar-2.png" class="img-fluid" />
        </div>
        <div v-if="Lang.SelectedLanguage=='tr'">

            <div style="max-width:570px;margin:auto;">
                <p>
                    Ülker, 2020 yılında konsolide olarak yaklaşık 183 milyon TL yatırım bedeliyle aşağıdaki modernizasyon çalışmalarını gerçekleştirmiştir:
                    <br /> <br />
                    • Kolileme makinası alımı, <br />
                    • Üretim besleme ünitesi yenilenmesi,    <br />
                    • Servis tanklarının yenilenmesi,    <br />
                    • Yeni ambalaj makinesi alımı,   <br />
                    • Yeni hat kurulumu.        <br />

                </p>
            </div>
        </div>
        <div v-if="Lang.SelectedLanguage=='en'">
            <div style="max-width:570px;margin:auto;">
                <p>
                    market position with new capital investments that included new installations in the factories, capacity increases, modifications to production lines, product upgrades, and improvements in hygienic conditions and warehousing processes.
                </p><p>
                    Aiming to increase its customer satisfaction by making investments, the Company improves product quality more and more and makes its cost structure more competitive by enhancing its operational efficiency.
                </p><p>
                    In 2020, Ülker carried out the following modernization activities with an approximately TL 183 million investment on a consolidated basis:
                </p><p>
                    •	Packing machinery purchase, <br />
                    •	Production feeding unit renewal,                 <br />
                    •	Service tank renewal,              <br />
                    •	New packaging machinery purchase,    <br />
                    •	New production line assembly.    <br />

                </p>
            </div>

        </div>
        <router-link :to="'/'+Lang.SelectedLanguage" class="backhome"> {{Lang.get('backhome')}} </router-link>
        <a v-if="Lang.SelectedLanguage=='tr'" target="_blank" href="/files/Yatırımlar.pdf" class="download">{{Lang.get('download')}}</a>
        <a v-if="Lang.SelectedLanguage=='en'" target="_blank" href="/files/Investments.pdf" class="download">{{Lang.get('download')}}</a>
    </div>




</template>

<script>
    import lng from "../store/languages";

    export default {
        name: 'Chairman',
        components: {},
        data() {
            return {
                Lang: lng,
            }
        }
    }</script>
